export type EventProps = {
  action: string;
  allowCustomScripts?: boolean;
  callback?(): void;
  category?: string;
  label?: string;
  nonInteraction?: boolean;
  sendTo?: string | string[];
  transport?: "beacon";
  value?: number;
};

const fetchOptions: RequestInit = {
  credentials: "omit",
  // keepalive works like `navigator.sendBeacon` and will continue the
  // request even if the browser/tab is closed
  keepalive: true,
  referrerPolicy: "same-origin",
};

const isAnalyticsEnabled = () => typeof window?.gtag !== "undefined";

export function trackCustomEvent({
  action,
  allowCustomScripts,
  callback,
  category,
  label,
  nonInteraction,
  sendTo,
  transport,
  value,
}: EventProps) {
  fetch(
    `/ping?event=${action}&category=${category}&label=${label}`,
    fetchOptions
  );
  if (isAnalyticsEnabled()) {
    window.gtag("event", action, {
      allow_custom_scripts: allowCustomScripts,
      event_callback: callback,
      event_category: category,
      event_label: label,
      non_interaction: nonInteraction,
      send_to: sendTo,
      transport_type: transport,
      value,
    });
  } else {
    callback?.();
  }
}

export function trackCustomException(description: string, fatal?: boolean) {
  if (isAnalyticsEnabled()) {
    window.gtag("event", "exception", {
      description,
      fatal,
    });
  }
  fetch(`/ping?error=${description}&fatal=${fatal}`, fetchOptions);
}

// EEA + UK
export const consentModeCountries = [
  "at",
  "be",
  "bg",
  "cy",
  "cz",
  "de",
  "dk",
  "ee",
  "es",
  "fi",
  "fr",
  "gb",
  "gr",
  "hr",
  "hu",
  "ie",
  "is",
  "it",
  "li",
  "lt",
  "lu",
  "lv",
  "mt",
  "nl",
  "no",
  "pl",
  "pt",
  "ro",
  "se",
  "si",
  "sk",
];

type ConsentParams = {
  ad_personalization: "granted" | "denied";
  ad_storage: "granted" | "denied";
  ad_user_data: "granted" | "denied";
  analytics_storage: "granted" | "denied";
};

const getCookieQConsents = (): undefined | ConsentParams => {
  const rawCookieQCookie = document.cookie
    .split("; ")
    .find((cookie) => cookie.startsWith("__CONSENT"));

  if (!rawCookieQCookie) {
    return;
  }

  const [, rawCookieQValue] = rawCookieQCookie.split("=");
  if (!rawCookieQValue) {
    return;
  }

  const cookieQValue = rawCookieQValue.toLowerCase().trim();
  if (cookieQValue === "r") {
    // Rejected all
    return {
      ad_storage: "denied",
      ad_user_data: "denied",
      ad_personalization: "denied",
      analytics_storage: "denied",
    };
  }

  if (cookieQValue.match(/^t\d+$/)) {
    // Accepted all
    return {
      ad_storage: "granted",
      ad_user_data: "granted",
      ad_personalization: "granted",
      analytics_storage: "granted",
    };
  }

  const cookieQValueParts = cookieQValue.split("^!");
  if (cookieQValueParts.length < 2) {
    return;
  }

  const categories = cookieQValueParts.at(-1);
  if (!categories) {
    return;
  }

  const analyticsConsent = categories.includes("analytics")
    ? "granted"
    : "denied";

  const targetingConsent = categories.includes("targeting")
    ? "granted"
    : "denied";

  return {
    ad_personalization: targetingConsent,
    ad_storage: targetingConsent,
    ad_user_data: targetingConsent,
    analytics_storage: analyticsConsent,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function trackCustomDimensions(
  dimensions: Record<string, any>,
  countryCode: string,
  hasCookieQ: boolean,
  gaTrackingIds?: Array<string> | null
) {
  if (isAnalyticsEnabled() && gaTrackingIds?.length) {
    const isConsentModeEnabled = consentModeCountries.includes(countryCode);

    if (isConsentModeEnabled) {
      window.gtag("consent", "default", {
        ad_storage: "denied",
        ad_user_data: "denied",
        ad_personalization: "denied",
        analytics_storage: "denied",
      } as ConsentParams); // Coersion required as `ad_user_data` is missing from node_modules/@types/gtag.js/index.d.ts
    }

    [
      "G-K257S23T0D", // GA4 global rollup
      ...gaTrackingIds,
    ].forEach((gaId) => {
      // Reconfigure GA to decorate all events with custom dimensions
      window.gtag("config", gaId, dimensions);
    });

    if (!(isConsentModeEnabled && hasCookieQ)) {
      return;
    }

    const cookieQConsent = getCookieQConsents();
    if (!cookieQConsent) {
      return;
    }
    window.gtag("consent", "update", cookieQConsent);
  }
}
