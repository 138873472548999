import { graphql, useStaticQuery } from "gatsby";
import React, { ReactNode } from "react";

import type { LexiconContextQuery } from "../../graphql-types";
import createCtx from "./createCtx";

type ExtranTranslations = {
  currentPage: string;
  sizes: string;
};
type Lang = "en" | "pt" | "fr" | "de" | "es" | "it" | "nl" | "pl" | "tr" | "vn";

const extraTranslationsGlobal: Record<Lang, ExtranTranslations> = {
  en: {
    currentPage: "Current page",
    sizes: "Sizes",
  },
  pt: {
    currentPage: "Página atual",
    sizes: "Tamanhos",
  },
  fr: {
    currentPage: "Page actuelle",
    sizes: "Tailles",
  },
  de: {
    currentPage: "Aktuelle Seite",
    sizes: "Größen",
  },
  es: {
    currentPage: "Página actual",
    sizes: "Tamaños",
  },
  it: {
    currentPage: "Pagina attuale",
    sizes: "Taglie",
  },
  nl: {
    currentPage: "Huidige pagina",
    sizes: "Maten",
  },
  pl: {
    currentPage: "Aktualna strona",
    sizes: "Rozmiary",
  },
  tr: {
    currentPage: "Geçerli sayfa",
    sizes: "Boyutlar",
  },
  vn: {
    currentPage: "Trang hiện tại",
    sizes: "Kích cỡ",
  },
};

type LexiconState = ExtranTranslations &
  Omit<Readonly<NonNullable<LexiconContextQuery["lexicon"]>>, "__typename">;

const [useLexicon, Provider] = createCtx<LexiconState>();

function LexiconProvider({ children }: { children: ReactNode }) {
  const data = useStaticQuery<LexiconContextQuery>(graphql`
    query LexiconContext {
      domain {
        lang
      }
      lexicon {
        benefits
        buyItNow
        certificates
        description
        facts
        home
        howToUseHeading
        ingredients
        invalidFormatValidationMessage
        maxLengthValidationMessage
        opensInNewTab
        pageNotFoundText
        products
        ratingFormatString
        readMore
        relatedProducts
        requiredFieldValidationMessage
        reviewCountFormatString
        reviewsHeading
        share
        skipToContent
        socialTitle
      }
    }
  `);

  if (!data.lexicon || !data.domain) {
    throw new Error("Could not query lexicon fields");
  }

  const lang = data.domain.lang.split("-")[0] as Lang;
  const translations = extraTranslationsGlobal[lang] || {};

  return (
    <Provider value={{ ...data.lexicon, ...translations }}>{children}</Provider>
  );
}

export { LexiconProvider, useLexicon };
