import "whatwg-fetch";

import { trackCustomException } from "./src/utilities/analytics";
import wrapWithProvider from "./wrap-with-provider";

export const onClientEntry = () => {
  if (typeof window.onerror === "object") {
    // https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/onerror#notes
    window.onerror = function (msg, url, lineNo, columnNo, error) {
      const description = [
        "Message: " + msg,
        "URL: " + url,
        "Line: " + lineNo,
        "Column: " + columnNo,
        "Error object: " + JSON.stringify(error),
      ].join(" - ");

      trackCustomException(description);
    };

    return false;
  }
};

export const wrapRootElement = wrapWithProvider;
