import { createContext, useContext } from "react";

/**
 * A helper to create a Context and Provider with no upfront default value, and
 * without having to check for undefined all the time.
 */
export default function createCtx<A extends unknown | null>() {
  const ctx = createContext<A | undefined>(undefined);
  function useCtx() {
    const value = useContext(ctx);
    if (value === undefined) {
      throw new Error("useCtx must be inside a Provider with a value");
    }
    return value;
  }
  return [useCtx, ctx.Provider] as const; // 'as const' makes TypeScript infer a tuple
}
